import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminPage.css';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirect
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate from react-router-dom
import AdminPaymentsPage from '../AdminPaymentsPage/AdminPaymentsPage';
const AdminPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [users, setUsers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [showPaymentsOverlay, setShowPaymentsOverlay] = useState(false); // State to control the overlay

  const navigate = useNavigate(); // Initialize useNavigate

  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${node_backend}/admin/login`, { email, password });
      if (response.data.success) {
        setIsAuthenticated(true);
        localStorage.setItem('authToken', response.data.token);
      } else {
        alert('Invalid credentials');
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed');
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await axios.get(`${node_backend}/admin/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.success) {
          setUsers(response.data.users.map(user => ({
            ...user,
            time: user.start_time || '12:00',
          })));
        } else {
          alert('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        alert('Failed to fetch users');
      }
    };

    if (isAuthenticated) {
      fetchUsers();
    }
  }, [isAuthenticated]);


  // Handle payments page redirection with auth check
  const navigateToPayments = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      alert('You are not authorized to view this page. Please log in.');
      navigate('/admin');
    } else {
      navigate('/admin/payments');
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const handlePlanChange = (userId, newPlan) => {
    const token = localStorage.getItem('authToken');
    axios.post(
      `${node_backend}/admin/update-plan`,
      { userId, newPlan },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      if (response.data.success) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, plan: newPlan } : user
          )
        );
      } else {
        alert('Failed to update plan');
      }
    }).catch((error) => {
      console.error('Error updating plan:', error);
      alert('Error updating plan');
    });
  };
  const handleAutomationToggle = async (userId, automated, scheduleTime) => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.post(
        `${node_backend}/admin/toggle-mode`,
        { userId, automated: !automated, scheduleTime },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data);
      if (response.data.success) {
        const newAutomatedState = !automated;
        const pythonEndpoint = newAutomatedState
          ? `${python_backend}/admin/strategy/start`
          : `${python_backend}/admin/strategy/stop`;

        try {
          await axios.post(
            pythonEndpoint,
            { userId, scheduleTime },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user._id === userId
                ? { ...user, automated: newAutomatedState, time: scheduleTime }
                : user
            )
          );
        } catch {
          console.error('Failed to start/stop the strategy');
          alert('Failed to start/stop the strategy');
        }
      } else {
        alert('Failed to update automation status');
      }
    } catch (error) {
      console.error('Error updating automation status:', error);
      alert('Error updating automation status');
    }
  };

  const handleTimeChange = (userId, newTime) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user._id === userId ? { ...user, time: newTime } : user
      )
    );
  };

  // Handle updating days remaining
  const handleDaysRemainingChange = (userId, daysRemaining) => {
    const token = localStorage.getItem('authToken');
    axios.post(
      `${node_backend}/admin/update-days-remaining`,
      { userId, daysRemaining },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      if (response.data.success) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, daysRemaining } : user
          )
        );
      } else {
        alert('Failed to update days remaining');
      }
    }).catch((error) => {
      console.error('Error updating days remaining:', error);
      alert('Error updating days remaining');
    });
  };

  return (
    <div className="container mt-5">
      {!isAuthenticated ? (
        <form onSubmit={handleLogin} className="card p-4">
          <h2 className="mb-4 text-center">Admin Login</h2>
          <div className="form-group mb-3">
            <label>Email:</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label>Password:</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block">
            Login
          </button>
        </form>
      ) : (
        <div>
          {/* <div className="text-right mb-4">
            <button onClick={navigateToPayments} className="btn btn-secondary">
              Go to Payments
            </button>
          </div> */}
          <div className="text-right mb-4">
            <button onClick={() => setShowPaymentsOverlay(true)} className="btn btn-secondary">
              View Payments
            </button>
          </div>
          <h2 className="mb-4 text-center">All Users</h2>
          <div className="table-responsive">
            <table className="table table-striped table-hover table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>#</th>
                  <th>User ID</th> {/* New column for User ID */}
                  {/* <th onClick={() => handleSort('email')}>Email</th> */}
                  <th onClick={() => handleSort('username')}>Username</th>
                  <th onClick={() => handleSort('phone')}>Phone</th>
                  <th>Credential Type</th> {/* New column for credType */}
                  <th>UID</th> {/* Displaying UID */}

                  {/* <th onClick={() => handleSort('plan')}>Plan</th> */}
                  <th onClick={() => handleSort('createdAt')}>Created At</th>
                  {/* <th onClick={() => handleSort('lastOnline')}>Last Online</th> */}
                  <th onClick={() => handleSort('creds')}>Credentials Status</th>
                  <th>Days Remaining</th> {/* New Column for Days Remaining */}
                  <th>Automated</th>
                  <th>Schedule Time</th>
                  <th>Update Plan</th>
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map((user, index) => (
                  <tr key={user._id}>
                    <td>{index + 1}</td>
                    <td>{user._id}</td> {/* Displaying the User ID */}
                    {/* <td>{user.email}</td> */}
                    <td>{user.username}</td>
                    <td>{user.phone}</td>
                    <td>{user.credType}</td> {/* Displaying credType */}
                    {/* Displaying credentials based on credType */}
                    <td>{user.credentials?.uid || 'N/A'}</td>

                    {/* <td>{user.plan}</td> */}
                    <td>{new Date(user.createdAt).toLocaleString()}</td>
                    {/* <td>{new Date(user.lastOnline).toLocaleString()}</td> */}
                    <td>{user.creds ? 'Yes' : 'No'}</td>
                    <td>
                      <input
                        type="number"
                        value={user.daysRemaining || 0}
                        onChange={(e) =>
                          handleDaysRemainingChange(user._id, parseInt(e.target.value, 10))
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <button
                        className={`btn btn-sm ${user.automated ? 'btn-success' : 'btn-secondary'}`}
                        onClick={() => handleAutomationToggle(user._id, user.automated, user.time)}
                      >
                        {user.automated ? 'Running' : 'Start'}
                      </button>
                    </td>
                    <td>
                      <input
                        type="time"
                        value={user.time}
                        onChange={(e) => handleTimeChange(user._id, e.target.value)}
                        className="form-control"
                        disabled={user.automated}
                      />
                    </td>
                    <td>
                      <select
                        value={user.plan}
                        onChange={(e) => handlePlanChange(user._id, e.target.value)}
                        className="form-control"
                      >
                        <option value="Gold">Gold</option>
                        <option value="Silver">Silver</option>
                        <option value="Free">Free</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
          {showPaymentsOverlay && (
            <div className="payments-overlay">
              <div className="payments-modal">
                <button onClick={() => setShowPaymentsOverlay(false)} className="close-button">X</button>
                <AdminPaymentsPage /> {/* Render the payments component inside the overlay */}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminPage;
