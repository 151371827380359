import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './ReferralsPage.css';

const Referrals = () => {
    const { authData } = useContext(AuthContext);
    const [referralLink, setReferralLink] = useState('');
    const [referralEarnings, setReferralEarnings] = useState(null); // To store referral earnings
    const currentUrl = window.location.origin;
    const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

    useEffect(() => {
        const fetchReferralLink = async () => {
            if (authData) {
                if (authData?.referralCode) {
                    // If referral code exists in authData, use it to generate the link
                    setReferralLink(`${currentUrl}/login?ref=${authData.referralCode}`);
                } else {
                    try {
                        // Fetch referral code from backend if not present in authData
                        const response = await axios.post(`${node_backend}/api/referrals/generate-referral-code`, {
                            userId: authData._id, // Replace with actual user ID from context
                        });
                        const code = response.data.referralCode;
                        setReferralLink(`${currentUrl}/register?ref=${code}`);
                    } catch (error) {
                        console.error('Error fetching referral link:', error);
                    }
                }
            }
        };

        // Fetch referral earnings
        const fetchReferralEarnings = async () => {
            if (authData?.referralEarnings) {
                try {
                    // const response = await axios.get(`${node_backend}/api/users/${authData._id}/referral-earnings`);
                    setReferralEarnings(authData.referralEarnings|| 0); // If no earnings, set to 0
                } catch (error) {
                    console.error('Error fetching referral earnings:', error);
                }
            }
        };

        fetchReferralLink();
        fetchReferralEarnings();
    }, [authData, currentUrl, node_backend]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink);
        alert('Referral link copied to clipboard!');
    };

    return (
        <div className="referrals-container">
            <h1>Spread the Word and Earn Big!</h1>
            <p>
                Join us in spreading this amazing technology with the world! For each referral you bring to us,
                you`ll earn a handsome fee based on the number of successful referrals.
            </p>

            {/* Referral Earnings Section */}
            <div className="referral-earnings">
                <h2>Your Referral Earnings</h2>
                <p>
                    {referralEarnings !== null
                        ? `₹${referralEarnings.toFixed(2)}`
                        : 'No earnings yet'}
                </p>
            </div>

            <div className="referral-table">
                <table>
                    <thead className='table-heading'>
                        <tr>
                            <th>Referrals</th>
                            <th>Earned Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Up to 10 referrals</td>
                            <td>20% of monthly fee</td>
                        </tr>
                        <tr>
                            <td>11 - 50 referrals</td>
                            <td>30% of monthly fee</td>
                        </tr>
                        <tr>
                            <td>51 - 100 referrals</td>
                            <td>40% of monthly fee</td>
                        </tr>
                        <tr>
                            <td>More than 100 referrals</td>
                            <td>50% of monthly fee</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p>
                You will receive a percentage of the amount each user pays monthly, based on the referral tier they fall into.
            </p>

            <div className="referral-link-section">
                <p>Your unique referral link:</p>
                <input type="text" value={referralLink} readOnly />
                <button onClick={copyToClipboard}>Copy Link</button>
            </div>
        </div>
    );
};

export default Referrals;
