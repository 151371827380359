import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import './ProfilePage.css';

const ProfilePage = () => {
  const { authData } = useContext(AuthContext);
  const [creds, setCreds] = useState({
    uid: '',
    pwd: '',
    token: '',
    api_key: '',
    type: 'Select ...',  // Default value set to 'Select ...'
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isDropdownInvalid, setIsDropdownInvalid] = useState(false); // New state for dropdown validation
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const formRef = useRef(null);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

  useEffect(() => {
    if (authData && authData.creds) {
      const fetchCreds = async () => {
        const token = localStorage.getItem('authToken');
        if (token) {
          const response = await axios.get(`${node_backend}/api/creds/get-creds/${authData._id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          if (response.data.success) {
            setCreds(response.data.creds);
          }
        }
      }
      fetchCreds();
    }
  }, [authData]);

  const handleCredsChange = (e) => {
    const { name, value } = e.target;
    setCreds({ ...creds, [name]: value });
    if (name === "type" && value !== "Select ...") {
      setIsDropdownInvalid(false); // Reset validation if a valid option is selected
    }
  };

  const handleCredsSubmit = async (e) => {
    e.preventDefault();
    if (!creds.type || creds.type === "Select ...") {
      setIsDropdownInvalid(true);
      return;
    }
    const token = localStorage.getItem('authToken');
    if (token) {
      const date = new Date().toISOString();
      try {
        const vc = creds.uid + '_U';
        const imei = 'abc1234';
        const credentials = { ...creds, vc, imei, date };
        const validateResponse = await axios.post(`${process.env.REACT_APP_PYTHON_BACKEND_URL}/api/validate-creds`,
          { credentials }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (validateResponse.data.success) {
          const saveResponse = await axios.post(`${process.env.REACT_APP_NODE_BACKEND_URL}/api/creds/save-creds`,
            {
              userId: authData._id,
              ...credentials
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            });

          if (saveResponse.data.success) {
            setIsEditing(false);
            authData.creds = true;
            alert('Credentials saved/updated successfully!');
            if (authData.automated) {
              try {
                // Stop the current automated strategy
                await axios.post(`${process.env.REACT_APP_PYTHON_BACKEND_URL}/api/strategy/stop`,
                  { userId: authData._id },
                  { headers: { Authorization: `Bearer ${token}` } }
                );

                // Start the automated strategy again
                await axios.post(`${process.env.REACT_APP_PYTHON_BACKEND_URL}/api/strategy/start`,
                  { userId: authData._id },
                  { headers: { Authorization: `Bearer ${token}` } }
                );

                alert('Automated strategy restarted successfully!');
              } catch (error) {
                console.error('Error restarting the automated strategy:', error);
                alert('Failed to restart the automated strategy. Please try again.');
              }
            }
            // window.location.reload();
          } else {
            alert(saveResponse.data.message);
          }
        } else {
          alert(validateResponse.data.message);
        }
      } catch (error) {
        console.error('Error during validation or saving credentials:', error);
        alert('Failed to validate or save credentials. Please try again.');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="profile-page">
      <h1>User Profile</h1>
      <div className="profile-info">
        {authData ? (
          <>
            <p><strong>Email:</strong> {authData.email}</p>
            <p><strong>Plan:</strong> {authData.plan}</p>
          </>
        ) : (
          <p>Loading user data...</p>
        )}
      </div>

      {!authData?.creds || isEditing ? (
        <form className="creds-form" onSubmit={handleCredsSubmit} ref={formRef}>
          <h2>{authData?.creds ? 'Edit Credentials' : 'Enter Credentials'}</h2>

          <label>
            Credential Type:
            <select
              name="type"
              value={creds.type}
              onChange={handleCredsChange}
              className={isDropdownInvalid ? 'invalid-dropdown' : ''}
            >
              <option value="Select ...">Select ...</option>
              <option value="Shoonya">Shoonya</option>
              <option value="Angel">Angel One</option>
            </select>
          </label>
          <label>
            UID:
            <input type="text" name="uid" value={creds.uid} onChange={handleCredsChange} disabled={authData?.creds} required />
          </label>
          <label>
            Password:
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="pwd"
                value={creds.pwd}
                onChange={handleCredsChange}
                required
              />
              <span onClick={togglePasswordVisibility}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </label>
          <label>
            TOTP Code:
            <input type="text" name="token" value={creds.token} onChange={handleCredsChange} required />
          </label>
          <label>
            API Key:
            <input type="text" name="api_key" value={creds.api_key} onChange={handleCredsChange} required />
          </label>
          <button type="submit" className="save-creds-btn">
            {authData?.creds ? 'Update Credentials' : 'Save Credentials'}
          </button>
        </form>
      ) : (
        <button onClick={() => setIsEditing(true)} className="edit-creds-btn">
          Edit Credentials
        </button>
      )}
    </div>
  );
};

export default ProfilePage;
